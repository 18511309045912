import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPen, faTrash} from '@fortawesome/free-solid-svg-icons';

function PersonCounter({ person, onUpdate, onDelete }) {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(person.name);
  const [count, setCount] = useState(person.count);
  const [delay] = useState(() => Math.random() * (2000 - 100) + 100);

  const handleIncrement = (value) => {
    setCount(prevCount => prevCount + value);
    onUpdate({...person, count: count + value});
  };

  const handleDecrement = () => {
    if(count > 0 ) {
    setCount(prevCount => prevCount > 0 ? prevCount - 1 : 0);
    onUpdate({...person, count: count - 1});
    }
  };

  const toggleEditing = () => {
    if (isEditing) {
      finishEditing();
    } else {
      setIsEditing(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      finishEditing();
    }
  };

  const handleBlur = () => {
    finishEditing();
  };

  const finishEditing = () => {
    onUpdate({ ...person, name, count });
    setIsEditing(false);
  };

  return (
    //<div className="mb-2 flex items-center space-x-4 p-4 bg-white rounded shadow">
    <div className="flex-wrap justify-center mb-2 flex items-center lg:space-x-4 lg:p-4 rounded shadow ">
      {isEditing ? (
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          maxLength={15}
          className="flex-1 p-2 rounded border border-gray-300 mr-3 ml-1"
        />
      ) : (
        <div className="flex-2 text-lg font-medium cursor-pointer ml-1 w-52" onClick={toggleEditing}>
          {name || 'Unknown'}
        </div>
      )}

      <div className="flex items-center space-x-1 my-2 lg:space-x-2 mr-2">
        <button className="animate-pulse w-12 h-12 rounded-full flex items-center justify-center text-2xl font-bold text-white border-2 border-color-2 hover:bg-gold-light"
          style={{
            background: 'radial-gradient(circle, #d69200 40%, white)',
            animationDelay: `${delay}ms`
          }}>
          {count}
        </button>
        {!isEditing && (
          <>
            <button onClick={() => handleDecrement()} className="w-8 h-8 rounded-full  text-black flex items-center justify-center"
              style={{
                background: 'radial-gradient(circle, white, #d69200)'
              }}>
              -1 
            </button>
            <button onClick={() => handleIncrement(1)} className="w-8 h-8 rounded-full bg-black text-white flex items-center justify-center" >
            +1
            </button>
            <button onClick={() => handleIncrement(2)} className="w-8 h-8 rounded-full bg-black text-white flex items-center justify-center" >
            +2
            </button>
            <button onClick={() => handleIncrement(3)} className="w-8 h-8 rounded-full bg-black text-white flex items-center justify-center" >
            +3
            </button>
          </>
        )}
        {isEditing && (
          <button onClick={() => onDelete(person.id)} className="w-8 h-8 rounded-full bg-red-500 text-white flex items-center justify-center">
            <FontAwesomeIcon icon={faTrash} />
          </button>
        )}
        <button onClick={toggleEditing} className="w-8 h-8 rounded-full bg-blue-500 text-white flex items-center justify-center border-2 border-color-2"
          style={{
            background: 'radial-gradient(circle, #d69200, white)'
        }}>
          {isEditing ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faPen} />}
        </button>
      </div>
    </div>
  );
}

export default PersonCounter;
