import React, { useState, useEffect  } from 'react';
import PersonCounter from './components/PersonCounter';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import Footer from './components/Footer';

function App() {
  const [people, setPeople] = useState(() => {
    // Load saved game state from localStorage, or initialize empty if none exists
    const savedPeople = localStorage.getItem('people');
    return savedPeople ? JSON.parse(savedPeople) : [];
  });

  // Effect to store people in localStorage when people state changes
  useEffect(() => {
    localStorage.setItem('people', JSON.stringify(people));
  }, [people]);

  const addPerson = () => {
    setPeople([...people, { id: uuidv4(), name: '', count: 0 }]);
  };

  const updatePerson = (updatedPerson) => {
    const updatedPeople = people.map(p =>
      p.id === updatedPerson.id ? updatedPerson : p
    );
    setPeople(updatedPeople);
  };

  const deletePerson = (id) => {
    const newPeople = people.filter(person => person.id !== id);
    setPeople(newPeople);
  };


  const resetAll = () => {
    // Clear the people array and remove from localStorage
    setPeople([]);
    localStorage.removeItem('people');
  };

  return (
    <>
    <main className="min-h-screen flex flex-col justify-between">
      <section className="flex-grow  lg:self-center lg:justify-center lg:w-1/2">
        <div className="flex justify-center items-center">
          <h1 className="font-orbitron text-2xl mt-3">Quest Tower</h1>
        </div>
        <div className="p-4 font-orbitron w-full">
          {people.map(person => (
            <PersonCounter key={person.id} person={person} onUpdate={updatePerson} onDelete={deletePerson} />
          ))}
        <button  onClick={addPerson} className="mt-4 bg-color-2 rounded-md text-white px-3 py-1">
          Add Person
        </button>
      </div>
      </section>
      <button onClick={resetAll} className="bg-transparent border-black border-2 text-black px-3 py-1 rounded-lg absolute top-3 right-3"> <FontAwesomeIcon icon={faRotateRight} />  </button>
      <Footer />
      </main>
    </>
  );
}

export default App;
